body {
  background-color: white;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: AltaRegular;
  src: local('AltaRegular'),
    url('./fonts/Alta/Alta_regular.otf') format('truetype');
  font-weight: auto;
}

@font-face {
  font-family: AltaLight;
  src: local('AltaLight'),
    url('./fonts/Alta/Alta_light.otf') format('truetype');
  font-weight: auto;
}

@font-face {
  font-family: AltaCaption;
  src: local('AltaCaption'),
    url('./fonts/Alta/Alta_caption.otf') format('truetype');
  font-weight: auto;
}

@font-face {
  font-family: LexendMegaBlack;
  src: local('LexendMega-Black.ttf'),
    url('./fonts/Lexend_Mega/static/LexendMega-Black.ttf') format('truetype');
  font-weight: auto;
}

@font-face {
  font-family: LexendMegaBold;
  src: local('LexendMega-Bold.ttf'),
    url('./fonts/Lexend_Mega/static/LexendMega-Bold.ttf') format('truetype');
  font-weight: auto;
}

@font-face {
  font-family: LexendMegaExtraBold;
  src: local('LexendMega-ExtraBold.ttf'),
    url('./fonts/Lexend_Mega/static/LexendMega-ExtraBold.ttf') format('truetype');
  font-weight: auto;
}

@font-face {
  font-family: LexendMegaExtraLight;
  src: local('LexendMega-ExtraLight.ttf'),
    url('./fonts/Lexend_Mega/static/LexendMega-ExtraLight.ttf') format('truetype');
  font-weight: auto;
}

@font-face {
  font-family: LexendMegaLight;
  src: local('LexendMega-Light.ttf'),
    url('./fonts/Lexend_Mega/static/LexendMega-Light.ttf') format('truetype');
  font-weight: auto;
}

@font-face {
  font-family: LexendMegaMedium;
  src: local('LexendMega-Medium.ttf'),
    url('./fonts/Lexend_Mega/static/LexendMega-Medium.ttf') format('truetype');
  font-weight: auto;
}

@font-face {
  font-family: LexendMegaRegular;
  src: local('LexendMega-Regular.ttf'),
    url('./fonts/Lexend_Mega/static/LexendMega-Regular.ttf') format('truetype');
  font-weight: auto;
}

@font-face {
  font-family: LexendMegaSemiBold;
  src: local('LexendMega-SemiBold.ttf'),
    url('./fonts/Lexend_Mega/static/LexendMega-SemiBold.ttf') format('truetype');
  font-weight: auto;
}

@font-face {
  font-family: LexendMegaThin;
  src: local('LexendMega-Thin.ttf'),
    url('./fonts/Lexend_Mega/static/LexendMega-Thin.ttf') format('truetype');
  font-weight: auto;
}

@font-face {
  font-family: LeagueGothic;
  src: local('LeagueGothic-Regular.ttf'),
  url('./fonts/League_Gothic/static/LeagueGothic/LeagueGothic-Regular.ttf') format('truetype');
  font-weight: auto;
}

@font-face {
  font-family: LeagueGothicCondensed;
  src: local('LeagueGothic_Condensed-Regular.ttf'),
  url('./fonts/League_Gothic/static/LeagueGothic_Condensed/LeagueGothic_Condensed-Regular.ttf') format('truetype');
  font-weight: auto;
}

@font-face {
  font-family: LeagueGothicSemiCondensed;
  src: local('LeagueGothic_SemiCondensed-Regular.ttf'),
  url('./fonts/League_Gothic/static/LeagueGothic_SemiCondensed/LeagueGothic_SemiCondensed-Regular.ttf') format('truetype');
  font-weight: auto;
}

@font-face {
  font-family: AmalfiCoast;
  src: local('Amalfi Coast.ttf'),
  url('./fonts/Amalfi Coast.ttf') format('truetype');
  font-weight: auto;
}

@font-face {
  font-family: 'JosefinSans-Bold';
  src: local('JosefinSans-Bold.ttf'),
  url('./fonts/Josefin_Sans/static/JosefinSans-Bold.ttf') format('truetype');
  font-weight: auto;
}
@font-face {
  font-family: 'JosefinSans-BoldItalic';
  src: local('JosefinSans-BoldItalic.ttf'),
  url('./fonts/Josefin_Sans/static/JosefinSans-BoldItalic.ttf') format('truetype');
  font-weight: auto;
}
@font-face {
  font-family: 'JosefinSans-ExtraLight';
  src: local('JosefinSans-ExtraLight.ttf'),
  url('./fonts/Josefin_Sans/static/JosefinSans-ExtraLight.ttf') format('truetype');
  font-weight: auto;
}
@font-face {
  font-family: 'JosefinSans-ExtraLightItalic';
  src: local('JosefinSans-ExtraLightItalic.ttf'),
  url('./fonts/Josefin_Sans/static/JosefinSans-ExtraLightItalic.ttf') format('truetype');
  font-weight: auto;
}
@font-face {
  font-family: 'JosefinSans-Italic';
  src: local('JosefinSans-Italic.ttf'),
  url('./fonts/Josefin_Sans/static/JosefinSans-Italic.ttf') format('truetype');
  font-weight: auto;
}
@font-face {
  font-family: 'JosefinSans-Light';
  src: local('JosefinSans-Light.ttf'),
  url('./fonts/Josefin_Sans/static/JosefinSans-Light.ttf') format('truetype');
  font-weight: auto;
}
@font-face {
  font-family: 'JosefinSans-LightItalic';
  src: local('JosefinSans-LightItalic.ttf'),
  url('./fonts/Josefin_Sans/static/JosefinSans-LightItalic.ttf') format('truetype');
  font-weight: auto;
}
@font-face {
  font-family: 'JosefinSans-Medium';
  src: local('JosefinSans-Medium.ttf'),
  url('./fonts/Josefin_Sans/static/JosefinSans-Medium.ttf') format('truetype');
  font-weight: auto;
}
@font-face {
  font-family: 'JosefinSans-MediumItalic';
  src: local('JosefinSans-MediumItalic.ttf'),
  url('./fonts/Josefin_Sans/static/JosefinSans-MediumItalic.ttf') format('truetype');
  font-weight: auto;
}
@font-face {
  font-family: 'JosefinSans-Regular';
  src: local('JosefinSans-Regular.ttf'),
  url('./fonts/Josefin_Sans/static/JosefinSans-Regular.ttf') format('truetype');
  font-weight: auto;
}
@font-face {
  font-family: 'JosefinSans-SemiBold';
  src: local('JosefinSans-SemiBold.ttf'),
  url('./fonts/Josefin_Sans/static/JosefinSans-SemiBold.ttf') format('truetype');
  font-weight: auto;
}
@font-face {
  font-family: 'JosefinSans-SemiBoldItalic';
  src: local('JosefinSans-SemiBoldItalic.ttf'),
  url('./fonts/Josefin_Sans/static/JosefinSans-SemiBoldItalic.ttf') format('truetype');
  font-weight: auto;
}
@font-face {
  font-family: 'JosefinSans-Thin';
  src: local('JosefinSans-Thin.ttf'),
  url('./fonts/Josefin_Sans/static/JosefinSans-Thin.ttf') format('truetype');
  font-weight: auto;
}
@font-face {
  font-family: 'JosefinSans-ThinItalic';
  src: local('JosefinSans-ThinItalic.ttf'),
  url('./fonts/Josefin_Sans/static/JosefinSans-ThinItalic.ttf') format('truetype');
  font-weight: auto;
}
